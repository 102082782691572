import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetTouchBanner from '../components/Get Touch Banner/'
import GetTouchForm from '../components/Get Touch Inquiries/'

const IndexPage = ({location}) => {
    const initial_data_query = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "cG9zdDoxNg==") {
          title
        }
      }
    }
  `)

  let page_title = initial_data_query.wpgraphql;
  let title = page_title.page.title;
  

  return(
    <div id="get_touch">
        <Layout>
            <SEO title={title} />
            <div className="get_touch_wrapper">
                <GetTouchBanner/>
                <GetTouchForm pageUrl={location} />
            </div>
        </Layout>
    </div>
  )
}

export default IndexPage
