import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

const TouchBanner = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.

    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            pages(where: { title: "Get in Touch" }) {
                edges {
                    node {
                        title
                        slug
                        id
                        acf_get_in_touch_Banner {
                            getInTouchBannerTitle
                            getInTouchBannerDescription
                            getInTouchBannerImage {
                                altText
                                sourceUrl
                            }
                            bannerImageXs {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
    }
    

    `)

    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);

    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
    let curr_api = process.env.GATSBY_API_URL;

    fetch(`${curr_api}/graphql`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
        query: `
        {
            pages(where: { title: "Get in Touch" }) {
                edges {
                    node {
                        title
                        slug
                        id
                        acf_get_in_touch_Banner {
                            getInTouchBannerTitle
                            getInTouchBannerDescription
                            getInTouchBannerImage {
                                altText
                                sourceUrl
                            }
                            bannerImageXs {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
        
        `,
        }),
    })
    .then(res => res.json())
    .then(data => updateData(data.data))
    .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])


    // Storing All Live Data in Variables to display it

    let bannerData = existingData.pages.edges[0].node.acf_get_in_touch_Banner;
    
    return (
        <div className="banner_section">
            <div className="inner">
                <div className='bannerImg'>

                    <div className='img_lg xs-hide'>
                        {
                            bannerData.getInTouchBannerImage ? (
                                <img src={bannerData.getInTouchBannerImage.sourceUrl} alt={bannerData.getInTouchBannerImage.altText}/>
                            ):null
                        }
                    </div>

                    <div className='img_xs xs-show'>
                        {
                            bannerData.bannerImageXs ? (
                                <img src={bannerData.bannerImageXs.sourceUrl} alt={bannerData.bannerImageXs.sourceUrl}/>
                            ):null
                        }
                    </div>

                    <div className='banner_text'>

                        <div className='container'>

                            <div className='content_wrapper'>

                                {
                                    bannerData.getInTouchBannerTitle ? (
                                        <h3 className='banner_title color_green section_title_lg'> {bannerData.getInTouchBannerTitle} </h3>
                                    ):null
                                }
                                
                                <div className='banner_descr'>
                                {
                                    bannerData.getInTouchBannerDescription ? (
                                        require('html-react-parser')(
                                            bannerData.getInTouchBannerDescription
                                        )
                                    ):null
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TouchBanner
