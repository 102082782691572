import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import window from 'global'

const InquiryForm = ({pageUrl}) => {
    // Querying and storing Data Stored in Gatsby GraphQL query.

    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            pages(where: { title: "Get in Touch" }) {
                edges {
                    node {
                        title
                        slug
                        id
                        acf_get_in_touch_Form {
                            contactFormEmailId
                            formTitle
                            formDescription
                            ukLocationTitle
                            ukLocationDescription
                            ukLocationContactNumber
                            ukLocationEmail
                            ukLocation
                            indiaLocationTitle
                            indiaLocationDescription
                            indiaLocationContactNumber
                            indiaLocationEmail
                            indiaLocation
                            usaLocationContactNumber
                            usaLocationDescription
                            usaLocationEmail
                            usaLocationTitle
                        }
                    }
                }
            }
        }
    }
    

    `)

    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);

    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
    let curr_api = process.env.GATSBY_API_URL;

    fetch(`${curr_api}/graphql`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
        query: `
        {
            pages(where: { title: "Get in Touch" }) {
                edges {
                    node {
                        title
                        slug
                        id
                        acf_get_in_touch_Form {
                            contactFormEmailId
                            formTitle
                            formDescription
                            ukLocationTitle
                            ukLocationDescription
                            ukLocationContactNumber
                            ukLocationEmail
                            ukLocation
                            indiaLocationTitle
                            indiaLocationDescription
                            indiaLocationContactNumber
                            indiaLocationEmail
                            indiaLocation
                            usaLocationContactNumber
                            usaLocationDescription
                            usaLocationEmail
                            usaLocationTitle
                        }
                    }
                }
            }
        }
        
        `,
        }),
    })
    .then(res => res.json())
    .then(data => updateData(data.data))
    .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])

    let hideThanksPopup = () => {
        var base_url = window.location.origin;  
        window.location.href = base_url+"/get-in-touch/thankyou" 
      }
    // Storing All Live Data in Variables to display it

    let inquiryData = existingData.pages.edges[0].node.acf_get_in_touch_Form;
    
    // Form Validations
    

    const validateName = (e) => {
        let name = e.value.trim();
        let regName = /^[a-zA-Z ]*$/

        // If field is not empty
        if(name){
            e.nextSibling.classList.remove('show_error')

            // If field does not matches regex
            if (!regName.test(name)){
                e.nextSibling.textContent = '*Please enter a valid Name';
                e.nextSibling.classList.add('show_error')
                return false;
            }
            // If field matches regex
            else{
                e.nextSibling.classList.remove('show_error')
            }
        }
        // If field is empty
        else{
            e.nextSibling.textContent = '*Required';
            e.nextSibling.classList.add('show_error')
            return false;
        }
        return true;
    }

    const validateEmail = (e) => {
        let email = e.value.trim();
        let regEmail = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/

        // If field is not empty
        if(email){
            e.nextSibling.classList.remove('show_error')

            // If field does not matches regex
            if (!regEmail.test(email)){
                e.nextSibling.textContent = '*Please enter a valid Email';
                e.nextSibling.classList.add('show_error')
                return false;
            }
            // If field matches regex
            else{
                e.nextSibling.classList.remove('show_error')
            }
        }
        // If field is empty
        else{
            e.nextSibling.textContent = '*Required';
            e.nextSibling.classList.add('show_error')
            return false;
        }
        return true;
    }

    const validatePhone = (e) => {
        let phone = e.value.trim();
        let regPhone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

        // If field is not empty
        if(phone){
            e.nextSibling.classList.remove('show_error')

            // If field does not matches regex
            if (!regPhone.test(phone)){
                e.nextSibling.textContent = '*Please enter a valid Phone Number';
                e.nextSibling.classList.add('show_error')
                return false;
            }
            // If field matches regex
            else{
                e.nextSibling.classList.remove('show_error')
            }
        }
        // If field is empty
        else{
            e.nextSibling.textContent = '*Required';
            e.nextSibling.classList.add('show_error')
            return false;
        }
        return true;
    }
     
    const validateMsg = (e) => {
        let msg = e.value.trim();

        // If field is not empty
        if(msg){
            e.nextSibling.classList.remove('show_error')
        }
        // If field is empty
        else{
            e.nextSibling.textContent = '*Required';
            e.nextSibling.classList.add('show_error')
            return false;
        }
        return true;
    }

    const validateAllFields = (e) => {
        let inputName = validateName(document.getElementById('fullname'));
        let inputEmail = validateEmail(document.getElementById('email'));
        let inputPhone = validatePhone(document.getElementById('phone'));
        let inputMsg = validateMsg(document.getElementById('msg'));

        if(inputName && inputEmail && inputPhone && inputMsg ){
            document.getElementById('mainSubmit').click();
        }
    }

    return (
        <div className="contact_section" id='get_in_touch'>
            {
            window.location!==undefined?
            window.location.search!==''?
              hideThanksPopup()  
            :''
            :''
            }
            <div>

                
                    <div className='location_section'>
                    <div className="flex_view_xs">
                    <div className="location-col">
                        <div className='upper_location location'>
                            <div className="half-container">
                            {
                                inquiryData.ukLocationTitle ? (
                                    <h3 className='location_title color_white section_title_lg'> {inquiryData.ukLocationTitle} </h3>
                                ):null
                            }

                            {
                                inquiryData.ukLocationDescription ? (
                                    <div className='location_descr color_white'>
                                        {require('html-react-parser')(
                                            inquiryData.ukLocationDescription
                                        )}
                                    </div>
                                ):null
                            }

                            <div className='detail_box'>
                                <ul className='item_ul'>
                                    <li className='item_li'>
                                        <a href={`tel:${inquiryData.ukLocationContactNumber}`} className="item flex_view_xs middle">
                                            <span className='icon'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 189.59 189.59">
                                                    <title>{"Phone_Orange"}</title>
                                                    <g data-name="Layer 2">
                                                        <g data-name="Layer 1">
                                                        <circle cx={94.8} cy={94.8} r={94.8} fill="#fff" />
                                                        <path
                                                            d="M139.33 116.83l-13.14-13.15c-4.7-4.69-12.68-2.81-14.56 3.29a8.94 8.94 0 01-10.33 5.63c-9.39-2.34-22.06-14.55-24.41-24.41-1.41-4.19 1.41-8.92 5.64-10.33 6.1-1.87 8-9.86 3.28-14.55L72.67 50.16c-3.76-3.28-9.39-3.28-12.68 0l-8.92 8.92c-8.92 9.39.94 34.28 23 56.34s47 32.39 56.34 23l8.92-8.92c3.29-3.75 3.29-9.38 0-12.67z"
                                                            fill="#f38555"
                                                        />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>

                                            {
                                                inquiryData.ukLocationContactNumber ? (
                                                    <span className='text color_white'> {inquiryData.ukLocationContactNumber} </span>
                                                ):null
                                            }
                                            
                                        </a>  
                                    </li>

                                    <li className='item_li'>
                                        <a href={`mailto:${inquiryData.ukLocationEmail}`} className="item flex_view_xs middle">
                                            <span className='icon'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 189.59 189.59">
                                                    <defs>
                                                        <style>{".prefix__cls-2{fill:#f38656 !important}"}</style>
                                                    </defs>
                                                    <title>{"Mail_Orange"}</title>
                                                    <g id="prefix__Layer_2" data-name="Layer 2">
                                                        <g id="prefix__Layer_1-2" data-name="Layer 1">
                                                        <circle cx={94.8} cy={94.8} r={94.8} fill="#fff" />
                                                        <path
                                                            className="prefix__cls-2"
                                                            d="M143.83 56.27H45.77a7 7 0 00-7 7v4L94.8 91l56-23.73v-4a7 7 0 00-6.97-7z"
                                                            fill="#f38656"
                                                        />
                                                        <path
                                                            className="prefix__cls-2"
                                                            d="M96.16 98a3.38 3.38 0 01-1.36.28 3.43 3.43 0 01-1.37-.28L38.76 74.87v51.45a7 7 0 007 7h98.06a7 7 0 007-7V74.87z"
                                                            fill="#f38656"
                                                        />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>

                                            {
                                                inquiryData.ukLocationEmail ? (
                                                    <span className='text color_white'> {inquiryData.ukLocationEmail} </span>
                                                ):null
                                            }
                                            
                                        </a>  
                                    </li>

                                    <li className='item_li'>
                                        <a href={inquiryData.ukLocation} target='_blank' className="item flex_view_xs middle">
                                            <span className='icon'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 189.59 189.59">
                                                    <title>{"Location_Orange"}</title>
                                                    <g data-name="Layer 2">
                                                        <g data-name="Layer 1">
                                                        <circle cx={94.8} cy={94.8} r={94.8} fill="#fff" />
                                                        <path
                                                            d="M94.8 37.87a38 38 0 00-33.4 56l31.32 56.63a2.37 2.37 0 004.15 0l31.33-56.66a38 38 0 00-33.4-56zm0 56.93a19 19 0 1119-19 19 19 0 01-19 19z"
                                                            fill="#f38555"
                                                        />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>

                                            <span className='text color_white'>Locate us</span>
                                            
                                        </a>  
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="location-col">
                        <div className='lower_location location'>
                                <div className="half-container">
                                {
                                    inquiryData.usaLocationTitle ? (
                                        <h3 className='location_title color_white section_title_lg'> {inquiryData.usaLocationTitle} </h3>
                                    ):null
                                }

                                {
                                    inquiryData.usaLocationDescription ? (
                                        <div className='location_descr color_white'>
                                            {require('html-react-parser')(
                                                inquiryData.usaLocationDescription
                                            )}
                                        </div>
                                    ):null
                                }

                                <div className='detail_box'>
                                    <ul className='item_ul'>
                                        <li className='item_li inline_li'>
                                            <a href={`tel:${inquiryData.usaLocationContactNumber}`} className="item flex_view_xs middle">
                                                <span className='icon'>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 189.59 189.59">
                                                        <title>{"Phone"}</title>
                                                        <g data-name="Layer 2">
                                                            <g data-name="Layer 1">
                                                            <circle cx={94.8} cy={94.8} r={94.8} fill="#fff" />
                                                            <path
                                                                d="M139.33 116.83l-13.14-13.15c-4.7-4.69-12.68-2.81-14.56 3.29a8.94 8.94 0 01-10.33 5.63c-9.39-2.34-22.06-14.55-24.41-24.41-1.41-4.19 1.41-8.92 5.64-10.33 6.1-1.87 8-9.86 3.28-14.55L72.67 50.16c-3.76-3.28-9.39-3.28-12.68 0l-8.92 8.92c-8.92 9.39.94 34.28 23 56.34s47 32.39 56.34 23l8.92-8.92c3.29-3.75 3.29-9.38 0-12.67z"
                                                                fill="#098484"
                                                            />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>

                                                {
                                                    inquiryData.usaLocationContactNumber ? (
                                                        <span className='text color_white'> {inquiryData.usaLocationContactNumber} </span>
                                                    ):null
                                                }
                                                
                                            </a>  
                                        </li>

                                        <li className='item_li inline_li'>
                                            <a href={`mailto:${inquiryData.usaLocationEmail}`} className="item flex_view_xs middle">
                                                <span className='icon'>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 189.59 189.59">
                                                        <title>{"mail"}</title>
                                                        <g id="prefix__Layer_2" data-name="Layer 2">
                                                            <g id="prefix__Layer_1-2" data-name="Layer 1">
                                                            <circle cx={94.8} cy={94.8} r={94.8} fill="#fff" />
                                                            <path
                                                                fill="#098484"
                                                                d="M143.83 56.27H45.77a7 7 0 00-7 7v4L94.8 91l56-23.73v-4a7 7 0 00-6.97-7z"
                                                            />
                                                            <path
                                                                fill="#098484"
                                                                d="M96.16 98a3.38 3.38 0 01-1.36.28 3.43 3.43 0 01-1.37-.28L38.76 74.87v51.45a7 7 0 007 7h98.06a7 7 0 007-7V74.87z"
                                                            />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>

                                                {
                                                    inquiryData.usaLocationEmail ? (
                                                        <span className='text color_white'> {inquiryData.usaLocationEmail} </span>
                                                    ):null
                                                }
                                                
                                            </a>  
                                        </li>
                                        
                                    </ul>
                                </div>
                                </div>
                            </div>

                            <div className='india_location location'>
                                <div className="half-container">
                                {
                                    inquiryData.indiaLocationTitle ? (
                                        <h3 className='location_title color_white section_title_lg'> {inquiryData.indiaLocationTitle} </h3>
                                    ):null
                                }

                                {
                                    inquiryData.indiaLocationDescription ? (
                                        <div className='location_descr color_white'>
                                            {require('html-react-parser')(
                                                inquiryData.indiaLocationDescription
                                            )}
                                        </div>
                                    ):null
                                }

                                <div className='detail_box'>
                                    <ul className='item_ul'>
                                        <li className='item_li inline_li'>
                                            <a href={`tel:${inquiryData.indiaLocationContactNumber}`} className="item flex_view_xs middle">
                                                <span className='icon'>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 189.59 189.59">
                                                        <title>{"Phone"}</title>
                                                        <g data-name="Layer 2">
                                                            <g data-name="Layer 1">
                                                            <circle cx={94.8} cy={94.8} r={94.8} fill="#fff" />
                                                            <path
                                                                d="M139.33 116.83l-13.14-13.15c-4.7-4.69-12.68-2.81-14.56 3.29a8.94 8.94 0 01-10.33 5.63c-9.39-2.34-22.06-14.55-24.41-24.41-1.41-4.19 1.41-8.92 5.64-10.33 6.1-1.87 8-9.86 3.28-14.55L72.67 50.16c-3.76-3.28-9.39-3.28-12.68 0l-8.92 8.92c-8.92 9.39.94 34.28 23 56.34s47 32.39 56.34 23l8.92-8.92c3.29-3.75 3.29-9.38 0-12.67z"
                                                                fill="#f1ad2c"
                                                            />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>

                                                {
                                                    inquiryData.indiaLocationContactNumber ? (
                                                        <span className='text color_white'> {inquiryData.indiaLocationContactNumber} </span>
                                                    ):null
                                                }
                                                
                                            </a>  
                                        </li>

                                        <li className='item_li inline_li'>
                                            <a href={`mailto:${inquiryData.indiaLocationEmail}`} className="item flex_view_xs middle">
                                                <span className='icon'>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 189.59 189.59">
                                                        <title>{"mail"}</title>
                                                        <g id="prefix__Layer_2" data-name="Layer 2">
                                                            <g id="prefix__Layer_1-2" data-name="Layer 1">
                                                            <circle cx={94.8} cy={94.8} r={94.8} fill="#fff" />
                                                            <path
                                                                fill="#f1ad2c"
                                                                d="M143.83 56.27H45.77a7 7 0 00-7 7v4L94.8 91l56-23.73v-4a7 7 0 00-6.97-7z"
                                                            />
                                                            <path
                                                                fill="#f1ad2c"
                                                                d="M96.16 98a3.38 3.38 0 01-1.36.28 3.43 3.43 0 01-1.37-.28L38.76 74.87v51.45a7 7 0 007 7h98.06a7 7 0 007-7V74.87z"
                                                            />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>

                                                {
                                                    inquiryData.indiaLocationEmail ? (
                                                        <span className='text color_white'> {inquiryData.indiaLocationEmail} </span>
                                                    ):null
                                                }
                                                
                                            </a>  
                                        </li>
                                        
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className='form_section'>
                        <div className="container">
                            <h3 className='section_title_lg color_green'> {inquiryData.formTitle} </h3>
                            <div className='form_descr'> 
                                {require('html-react-parser')(
                                    inquiryData.formDescription
                                )}  
                            </div>

                            <div className='form_wrapper'>

                                <form id="contactform" 
                                // action='' method="POST"
                                >

                                    {/*<input name="_redirect" type="hidden" id="name" value={`${pageUrl.origin}/thankyou`}/>*/}

                                    <div className='input_group'>
                                        <label htmlFor="fullname">Name</label>
                                        <input name="fullname" type="text" id="fullname" placeholder='Enter Name' onBlur={(e) => validateName(e.target)} />
                                        <span className='error_msg'></span>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className='input_group'>
                                                <label htmlFor="email">Email</label>
                                                <input name="email" type="email" id="email" placeholder='Enter Email' onBlur={(e) => validateEmail(e.target)}/>
                                                <span className='error_msg'></span>

                                        </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className='input_group'>
                                                <label htmlFor="phone">Phone Number</label>
                                                <input name="phone" type="text" maxLength="20" id="phone" placeholder='Enter Phone Number' onBlur={(e) => validatePhone(e.target)}/>
                                                <span className='error_msg'></span>

                                            </div>
                                        </div>
                                    </div>

                                    

                                    

                                    <div className='input_group'>
                                        <label htmlFor="msg">Message</label>
                                        <textarea name="msg" id="msg" rows="7" placeholder='Enter Message' onBlur={(e) => validateMsg(e.target)}></textarea>
                                        <span className='error_msg'></span>

                                    </div>

                                    <input name="_formsubmit_id" type="text" style={{ display: "none" }}/>

                                    <input value="Submit" id="mainSubmit" className="hide" type="submit"/>
                                    
                                    <div  className='submit_btn'>
                                        <span role="button" tabIndex="0" onClick={validateAllFields} onKeyDown={validateAllFields} className="btn_submit"> Submit </span>
                                    </div>

                                </form>

                            </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default InquiryForm
